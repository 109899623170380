/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import Breadcrumb from 'components/shared/Breadcrumb'
import Regisseurs from 'components/shared/Regisseurs'
import Highlights from 'components/shared/Highlights'
import Focus from 'components/shared/Focus'
import HeroCopy from 'components/shared/HeroCopy'

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...AboutUsFrag
    }
  }
`

const PageTemplate = ({
  data: {
    page: { title, acf, path, yoast_meta: yoast },
  },
  pageContext,
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{
          url:
            acf.banner.image.localFile &&
            acf.banner.image.localFile.childImageSharp.openGraph.src,
        }}
      />

      <Hero left content={acf.banner} className="mb-lg-3" />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <Focus content={acf.focus} />

      <Highlights content={acf.highlights} className="my-5" />

      <Regisseurs className="pt-5" />

      <HeroCopy className="mb-5" content={acf.working_together} />
    </Layout>
  )
}

export default PageTemplate
