import React from 'react'

// Components
import ButtonLine from 'components/elements/ButtonLine'

// Third Party
import styled from 'styled-components'

const StyledHighlights = styled.div`
  background: ${(props) => props.theme.color.gradient.main};
`

const Highlights = ({ className, content }) => {
  return (
    <StyledHighlights className={`${className ? `${className}` : ``}`}>
      <div className="container py-3">
        <h2 className="pl-lg-3 text-lg-left text-center font-weight-xl color-light mb-0 font-size-xml">
          ClearMind biedt oplossingen voor:
        </h2>
        <div className="row pt-4 pb-3">
          {content.relation.map((h) => (
            <div className="col-sm-6 col-lg-3 pb-3 pb-lg-0 d-flex justify-content-center">
              <ButtonLine min="222px" icon="arrow" to={h.path}>
                <p className="pl-3">{h.title}</p>
              </ButtonLine>
            </div>
          ))}
        </div>
      </div>
    </StyledHighlights>
  )
}

export default Highlights
